.nav {
  box-sizing: border-box;
  padding: 20px;
  padding-left: 0;
  max-width: 380px;
}

.nav__list {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-left: 20px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);
}

.nav__item {
  margin: 40px 0px;
}

.nav__link {
  display: block;

  font-family: @header;
  color: @white;
  text-decoration: none;
  font-size: 28px;
  line-height: 40px;
  letter-spacing: 0.05em;

  &:hover {
    color: @red;
  }
}

@media screen and (min-width: @mobile-xl) {
  .nav {
    padding: 40px;
    padding-left: 0;
  }

  .nav__list {
    padding-left: 40px;
  }

  .nav__social-list.social {
    margin-left: 20px;
  }
  
}

@media screen and (min-width: @tablet) {
  .nav {
    padding: 0;
    max-width: none;
  }

  .nav__list {
    display: none;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
    flex-grow: 1;
    border: none;
    
  }

  .nav__item {
    margin: 0;
  }

  .nav__link {
    margin: 0;
    font-family: @text;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
  }
  
  .nav__music.music {
    display: none;
  }

  .nav__social-list.social {
    padding: 0;
    margin-left: 0;
    margin-bottom: 20px;
  }

  .nav__social-list .social__item {
    margin: 0;
    margin-left: 20px;
  }
}

@media screen and (min-width: @tablet-xl) {
  .nav__list {
    margin-right: 50px;
  }
  .nav__link {
    margin: 0;
    font-family: @text;
    font-size: 16px;
    line-height: 18px;
  }
}