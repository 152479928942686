.social {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 20px;
  margin: 0;
  list-style: none;
}

.social__item {
  margin-right: 40px;
}

.social__link:hover svg {
  transform: rotate(30deg);
}

/*
@media screen and (min-width: @mobile-xl) {
  .social {
    padding: 50px 40px 40px 40px;
  }
}*/