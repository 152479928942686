.footer {
  background-image: url('../img/bg-noise.png');
  padding: 30px 0;
  text-align: center;
}

.footer__wrap {
  border-top: 2px solid rgba(227, 227, 227, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer__social-list.social {
  //margin: 0 auto;
  // padding-left: 50px;
  //padding-right: 50px;
  width: 280px;
  align-items: center;
  justify-content: center;

  & .social__item {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.footer__nav.nav {
  display: none;
}

.footer__copyright {
  font-style: normal;
  font-weight: normal;
  font-size: 8px;
  line-height: 9px;
  color: @light;
  margin: 0;

  a {
    color: rgb(99, 200, 234);
  }
}

@media screen and (min-width: @mobile-xl) {
  .footer__wrap {
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .footer__social-list.social {
    justify-content: flex-end;
  }
}

@media screen and (min-width: @tablet) {
  .footer__copyright {
    font-size: 14px;
    line-height: 16px;
    text-align: left;
  }

  .footer__nav.nav {
    display: block;
    max-width: 1220px;
    & .nav__list {
      display: flex;
      margin: 50px;
    }
  }
}
