.button {
  display: block;
  //margin: 0 auto;
  padding: 10px 25px;
  background-color: transparent;
  border: 1px solid #FFFFFF;
  cursor: pointer;
  font-family: @text;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 10px;
  line-height: 14px;
  color: @white;

  &:active {
    box-shadow: inset 0px 0px 10px 5px rgba(0, 0, 0, 0.5);
  }

  &:hover {
    background-color: @red;
  }
}

.button--mail {
  border-color: @red;
  width: 113px;
  border-radius: 1px;
}

.button--media {
  position: relative;
  width: 142px;
  padding: 3px 42px 3px 16px;
  font-size: 10px;

  &::after {
    position: absolute;
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    top: 3px;
    right: 16px;

    background: url('../../img/icon-play.svg') no-repeat;
  }
}

@media screen and (min-width: @mobile-xl) {
  .button {
    font-size: 12px;
  }

  .button--mail {
    width: 163px;
  }
}

@media screen and (min-width: @tablet-xl) {
  .button {
    font-size: 16px;
  }
}