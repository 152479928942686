.actor {
  padding: 0;
  text-align: center;
}

.actor__wrap {
  padding: 30px 0;
}

.actor__text {
  margin-bottom: 0;
}


.actor__buttons {
  display: flex;
  flex-direction: column;
  padding: 20px
  //margin: 0 auto;
}

.actor__button:first-child {
  margin-bottom: 20px;
}

@media screen and (min-width: @mobile-xl) {
  .actor__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 50px 0;
  }  

  .actor__image {
    margin-left: auto;
  }

  .actor__title {
    width: 300px;
    margin-top: -100px;
    margin-bottom: 0;
    align-self: flex-start;
    text-align: left;
  }

  .actor__text {
    width: 100%;
  }

  .actor__buttons {
    flex-direction: column;
    justify-content: space-evenly;
  }

  .actor__button:first-child {
    margin: 0;
  }
}

@media screen and (min-width: @tablet) {
  .actor__wrap {
    flex-direction: row-reverse;
    padding-bottom: 100px;
  }

  .actor__image {
    width: 370px;
    height: 416px;
    margin-left: 20px;
  }

  .actor__content {
    width: 50%;
  }
  .actor__title {
    margin-top: 0;
  }

  .actor__buttons {
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px 0;
    align-items: baseline;
  }

  .actor__button {
    margin-top: 10px;
  }

}

@media screen and (min-width: @desktop) {
  .actor__wrap {
    flex-direction: row-reverse;
    align-items: center;
    padding-top: 50px;
  }

  .actor__image {
    width: 640px;
    height: 719px;
  }

}
