.regatta {
  padding: 30px 0 0;
  text-align: center;
}

.regatta__text {
  position: relative;
}

.regatta__button-list.music {
  position: relative;
  justify-content: center;
  padding: 0;
  margin-top: 40px;

  &::before {
    position: absolute;
    content: '';
    display: block;
    left: calc(50% - 75px);
    top: -20px;
    width: 150px;
    height: 0;
    border-bottom: 2px solid rgba(227, 227, 227, 0.5);
  }
}

.regatta__quote {
  font-size: 12px;
  font-style: italic;
  color: @light;
}

@media screen and (min-width: @mobile-xl) {
  .regatta {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 50px 0;
  }

  .regatta__title {
    text-align: left;
    margin: 0;
    margin-top: -50px;
    margin-left: 30px;
  }

  .regatta__button-list.music {
    align-self: center;
  }
}

@media screen and (min-width: @tablet) {
  .regatta {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 100px;
    align-items: flex-start;
  }

  .regatta__content {
    width: 45%;
  }

  .regatta__image {
    width: 350px;
    height: 350px;
    margin-right: 30px;
  }

  .regatta__title {
    text-align: left;
    margin-left: 0;
    margin-top: 0;
  }

  .regatta__button-list.music {
    justify-content: flex-start;

    &::before {
      width: 100%;
      left: 0;
    }
  }
}

@media screen and (min-width: @desktop) {
  .regatta__image {
    width: 620px;
    height: 560px;
  }

  .regatta__title {
    margin-bottom: 50px;
  }
}
