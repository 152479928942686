.title-mob() {
  text-align: center;
  font-family: @header;
  font-size: 30px;
  line-height: 43px;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  @media screen and (min-width: 480px) {
    font-size: 34px;
    line-height: 48px;
  }

  @media screen and (min-width: 1000px) {
    font-size: 46px;
    line-height: 65px;
  }

  @media screen and (min-width: 1680px) {
    font-size: 75px;
    line-height: 106px;
  }

}

.text-mob() {
  color: @white;
  font-family: @text;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.05em;

  @media screen and (min-width: 480px) {
    font-size: 16px;
    line-height: 22px;
  }

  @media screen and (min-width: 768px) {
    
    text-align: left;
  }

  @media screen and (min-width: 1000px) {
    font-size: 20px;
    line-height: 28px;
  }

  @media screen and (min-width: 1680px) {
    font-size: 25px;
    line-height: 35px;
  }

}

.container() {
  margin: 0 20px;

  @media screen and (min-width: @tablet) {
    margin: 0 30px;
  }

  @media screen and (min-width: @tablet-xl) {
    max-width: 960px;
    margin: 0 auto;
  }

}