@font-face {
  font-family: "Lemon-Milk";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url("../fonts/lemonmilk.woff2"),
    url("../fonts/lemonmilk.woff");
}

@font-face {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url("../fonts/ubuntu-regular.woff2"),
    url("../fonts/ubuntu-regular.woff");
}
