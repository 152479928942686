.music {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-left: 20px;
  margin: 0;
  list-style: none;
}

.music__item {
  margin-right: 20px;
}

@media screen and (min-width: @mobile-xl) {
  .music {
    padding-left: 40px;
  }
}