html {
    box-sizing: border-box;
  }
  
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  
  img {
    max-width: 100%;
  }
  
  body {
    position: relative;
    background-color: @black;
    padding: 0;
    margin: 0;
    font-family: @text;
    font-size: 20px;
    font-weight: normal;
    line-height: 20px;
    color: @white;
    overflow-x: hidden;
  }
  
  main {
    background-image: url('../img/bg-noise.png');
  }
  
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  input[type="number"] {
    -moz-appearance: textfield;
  }
  
  .visually-hidden {
    position: absolute;
  
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
  
    white-space: nowrap;
  
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
  }

  .container {
    margin: 0 20px;

    @media screen and (min-width: @tablet) {
      margin: 0 30px;
    }

    @media screen and (min-width: @desktop) {
      max-width: 1220px;
      margin: 0 auto;
    }
  }

  .red {
    color: @red;
  }

  *[class$="__title"] {
    .title-mob;
  }
  
  *[class$="__text"] {
    .text-mob;
    text-align: center;
  }

  .divided {
    position: relative;

    &::before {
      position: absolute;
      content: '';
      display: block;
      top: 30px;
      left: 0;
      width: 200px;
      height: 0;
      border-bottom: 1px solid #FFFFFF;
      opacity: 0.5;

      @media screen and (min-width: @tablet) {
        width: 300px;
      }

      @media screen and (min-width: @tablet-xl) {
        width: 330px;
      }

      @media screen and (min-width: @desktop) {
        width: 50%;
      }
    }

    &::after {
      position: absolute;
      content: '';
      display: block;
      bottom: 30px;
      right: 0;
      width: 150px;
      height: 0;
      border-bottom: 1px solid #FFFFFF;
      opacity: 0.5;

      @media screen and (min-width: @mobile-xl) {
        width: 230px;
      }

      @media screen and (min-width: @tablet) {
        width: 300px;
      }

      @media screen and (min-width: @tablet-xl) {
        width: 400px;
      }
      @media screen and (min-width: @desktop) {
        width: 840px;
        bottom: 100px;
      }
    }
  }