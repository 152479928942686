/*base*/
@font-face {
  font-family: "Lemon-Milk";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url("../fonts/lemonmilk.woff2"), url("../fonts/lemonmilk.woff");
}
@font-face {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url("../fonts/ubuntu-regular.woff2"), url("../fonts/ubuntu-regular.woff");
}
html {
  box-sizing: border-box;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
img {
  max-width: 100%;
}
body {
  position: relative;
  background-color: #000000;
  padding: 0;
  margin: 0;
  font-family: 'Ubuntu', 'Arial', sans-serif;
  font-size: 20px;
  font-weight: normal;
  line-height: 20px;
  color: #ffffff;
  overflow-x: hidden;
}
main {
  background-image: url('../img/bg-noise.png');
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}
.container {
  margin: 0 20px;
}
@media screen and (min-width: 768px) {
  .container {
    margin: 0 30px;
  }
}
@media screen and (min-width: 1240px) {
  .container {
    max-width: 1220px;
    margin: 0 auto;
  }
}
.red {
  color: #EF1C25;
}
*[class$="__title"] {
  text-align: center;
  font-family: 'Lemon-Milk', 'Arial', sans-serif;
  font-size: 30px;
  line-height: 43px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}
@media screen and (min-width: 480px) {
  *[class$="__title"] {
    font-size: 34px;
    line-height: 48px;
  }
}
@media screen and (min-width: 1000px) {
  *[class$="__title"] {
    font-size: 46px;
    line-height: 65px;
  }
}
@media screen and (min-width: 1680px) {
  *[class$="__title"] {
    font-size: 75px;
    line-height: 106px;
  }
}
*[class$="__text"] {
  color: #ffffff;
  font-family: 'Ubuntu', 'Arial', sans-serif;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.05em;
  text-align: center;
}
@media screen and (min-width: 480px) {
  *[class$="__text"] {
    font-size: 16px;
    line-height: 22px;
  }
}
@media screen and (min-width: 768px) {
  *[class$="__text"] {
    text-align: left;
  }
}
@media screen and (min-width: 1000px) {
  *[class$="__text"] {
    font-size: 20px;
    line-height: 28px;
  }
}
@media screen and (min-width: 1680px) {
  *[class$="__text"] {
    font-size: 25px;
    line-height: 35px;
  }
}
.divided {
  position: relative;
}
.divided::before {
  position: absolute;
  content: '';
  display: block;
  top: 30px;
  left: 0;
  width: 200px;
  height: 0;
  border-bottom: 1px solid #FFFFFF;
  opacity: 0.5;
}
@media screen and (min-width: 768px) {
  .divided::before {
    width: 300px;
  }
}
@media screen and (min-width: 1000px) {
  .divided::before {
    width: 330px;
  }
}
@media screen and (min-width: 1240px) {
  .divided::before {
    width: 50%;
  }
}
.divided::after {
  position: absolute;
  content: '';
  display: block;
  bottom: 30px;
  right: 0;
  width: 150px;
  height: 0;
  border-bottom: 1px solid #FFFFFF;
  opacity: 0.5;
}
@media screen and (min-width: 480px) {
  .divided::after {
    width: 230px;
  }
}
@media screen and (min-width: 768px) {
  .divided::after {
    width: 300px;
  }
}
@media screen and (min-width: 1000px) {
  .divided::after {
    width: 400px;
  }
}
@media screen and (min-width: 1240px) {
  .divided::after {
    width: 840px;
    bottom: 100px;
  }
}
/*colors*/
/*fonts*/
/*breakpoints*/
/*blocks*/
.main-header {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000000;
  margin: 0;
  padding-bottom: 30px;
}
.main-header__navigation {
  display: flex;
  justify-content: flex-start;
  align-self: flex-start;
  padding-top: 30px;
}
.main-header__nav {
  position: absolute;
  display: block;
  top: 75px;
  left: -100%;
  background-color: #000000;
  border: 1px solid #ffffff;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  z-index: 2;
  transition: all 1s ease;
}
.main-header__social-list.social {
  display: none;
}
.main-header__welcome {
  text-align: center;
  margin: 0 20px;
  z-index: 1;
}
.main-header__content {
  text-align: center;
}
.main-header__menu-button {
  position: relative;
  align-self: flex-start;
  width: 24px;
  height: 27px;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  margin-right: 20px;
}
.main-header__menu-button .icon-burger-opened {
  display: none;
}
.main-header__menu-button.active .icon-burger-opened {
  display: inline;
}
.main-header__menu-button.active .icon-burger-closed {
  display: none;
}
.main-header__menu-button.active + .main-header__nav {
  left: 0;
  transition: all 1s ease;
}
.main-header__mail-button {
  margin: 0 auto;
}
@media (min-width: 480px) {
  .main-header {
    padding-bottom: 50px;
  }
  .main-header__text {
    margin-bottom: 30px;
  }
  .main-header__welcome {
    margin: 0 30px;
  }
}
@media (min-width: 768px) {
  .main-header {
    display: block;
  }
  .main-header__navigation {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .main-header__nav {
    flex-grow: 1;
    position: static;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-radius: none;
    border: none;
    width: auto;
    height: auto;
  }
  .main-header__nav .nav__list {
    opacity: 0;
  }
  .main-header__menu-button .icon-burger-opened {
    display: none;
  }
  .main-header__menu-button .icon-burger-closed {
    transition: transform 0.5s ease;
  }
  .main-header__menu-button.active .icon-burger-opened {
    display: none;
  }
  .main-header__menu-button.active .icon-burger-closed {
    display: inline;
    transform: rotate(90deg);
    transition: transform 0.5s ease;
  }
  .main-header__menu-button.active + .main-header__nav {
    display: flex;
  }
  .main-header__menu-button.active + .main-header__nav .nav__list {
    display: flex;
    opacity: 1;
    transition: opacity 2s ease;
  }
  .main-header__content {
    display: flex;
    flex-direction: row-reverse;
    margin: 0 30px;
  }
  .main-header__image {
    width: 415px;
    height: 415px;
  }
  .main-header__title {
    text-align: left;
    min-width: 385px;
    max-width: 430px;
    width: 100%;
  }
  .main-header__title span {
    display: block;
    text-align: left;
  }
  .main-header__title span.red {
    text-align: right;
  }
  .main-header__welcome {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
    width: 60%;
    padding-top: 70px;
    margin-right: -50px;
  }
  .main-header__text {
    margin-bottom: 40px;
  }
  .main-header__mail-button {
    margin: 0;
  }
}
@media screen and (min-width: 1000px) {
  .main-header__navigation {
    padding-bottom: 30px;
  }
  .main-header__content {
    max-width: 960px;
    margin: 0 auto;
  }
  .main-header__welcome {
    width: 45%;
    margin-right: 0;
  }
  .main-header__title {
    max-width: none;
  }
  .main-header__image {
    width: 548px;
    height: 548px;
    max-width: 100%;
  }
}
@media (min-width: 1240px) {
  .main-header__content {
    max-width: 1150px;
  }
  .main-header__image {
    margin-right: -30px;
  }
}
.about {
  background-color: #000000;
  background-image: url('../img/bg-noise.png');
  padding: 70px 0 50px;
  text-align: center;
}
.about__text {
  margin-bottom: 30px;
}
.about__remark {
  text-align: left;
  font-size: 0.8rem;
}
.about__button {
  margin: 0 auto;
}
@media screen and (min-width: 480px) {
  .about__wrap {
    display: flex;
    justify-content: center;
  }
  .about__image {
    width: 176px;
    height: 352px;
    flex-grow: 1;
  }
  .about__content {
    width: 60%;
    padding-left: 20px;
  }
  .about__title {
    margin: 0;
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 768px) {
  .about__image {
    float: left;
    height: 352px;
    width: 321px;
  }
  .about__content {
    width: 50%;
  }
  .about__text {
    text-align: left;
  }
  .about__button {
    margin-left: 0;
  }
}
@media screen and (min-width: 1000px) {
  .about__image {
    float: left;
    height: 412px;
    width: 308px;
  }
}
@media screen and (min-width: 1240px) {
  .about {
    padding: 170px 0;
  }
  .about__image {
    width: 622px;
    height: 640px;
  }
  .about__content {
    width: 705px;
  }
}
.button {
  display: block;
  padding: 10px 25px;
  background-color: transparent;
  border: 1px solid #FFFFFF;
  cursor: pointer;
  font-family: 'Ubuntu', 'Arial', sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 10px;
  line-height: 14px;
  color: #ffffff;
}
.button:active {
  box-shadow: inset 0px 0px 10px 5px rgba(0, 0, 0, 0.5);
}
.button:hover {
  background-color: #EF1C25;
}
.button--mail {
  border-color: #EF1C25;
  width: 113px;
  border-radius: 1px;
}
.button--media {
  position: relative;
  width: 142px;
  padding: 3px 42px 3px 16px;
  font-size: 10px;
}
.button--media::after {
  position: absolute;
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  top: 3px;
  right: 16px;
  background: url('../../img/icon-play.svg') no-repeat;
}
@media screen and (min-width: 480px) {
  .button {
    font-size: 12px;
  }
  .button--mail {
    width: 163px;
  }
}
@media screen and (min-width: 1000px) {
  .button {
    font-size: 16px;
  }
}
.actor {
  padding: 0;
  text-align: center;
}
.actor__wrap {
  padding: 30px 0;
}
.actor__text {
  margin-bottom: 0;
}
.actor__buttons {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.actor__button:first-child {
  margin-bottom: 20px;
}
@media screen and (min-width: 480px) {
  .actor__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 50px 0;
  }
  .actor__image {
    margin-left: auto;
  }
  .actor__title {
    width: 300px;
    margin-top: -100px;
    margin-bottom: 0;
    align-self: flex-start;
    text-align: left;
  }
  .actor__text {
    width: 100%;
  }
  .actor__buttons {
    flex-direction: column;
    justify-content: space-evenly;
  }
  .actor__button:first-child {
    margin: 0;
  }
}
@media screen and (min-width: 768px) {
  .actor__wrap {
    flex-direction: row-reverse;
    padding-bottom: 100px;
  }
  .actor__image {
    width: 370px;
    height: 416px;
    margin-left: 20px;
  }
  .actor__content {
    width: 50%;
  }
  .actor__title {
    margin-top: 0;
  }
  .actor__buttons {
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px 0;
    align-items: baseline;
  }
  .actor__button {
    margin-top: 10px;
  }
}
@media screen and (min-width: 1240px) {
  .actor__wrap {
    flex-direction: row-reverse;
    align-items: center;
    padding-top: 50px;
  }
  .actor__image {
    width: 640px;
    height: 719px;
  }
}
.if {
  padding: 30px 0 0;
  text-align: center;
}
.if__button-list.music {
  position: relative;
  justify-content: center;
  padding: 0;
  margin-top: 40px;
}
.if__button-list.music .music__item:last-child {
  margin-right: 0;
}
.if__button-list.music::before {
  position: absolute;
  content: '';
  display: block;
  left: calc(50% - 75px);
  top: -20px;
  width: 150px;
  height: 0;
  border-bottom: 2px solid rgba(227, 227, 227, 0.5);
}
@media screen and (min-width: 480px) {
  .if {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 50px 0;
  }
  .if__image {
    margin-right: auto;
  }
  .if__title {
    text-align: right;
    margin: 0;
    margin-top: -50px;
  }
  .if__button-list.music {
    align-self: center;
  }
}
@media screen and (min-width: 768px) {
  .if {
    flex-direction: row-reverse;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 100px;
    align-items: flex-start;
  }
  .if__content {
    width: 45%;
  }
  .if__image {
    width: 350px;
    height: 350px;
    margin-left: 30px;
  }
  .if__title {
    text-align: center;
    margin-top: 0;
  }
  .if__button-list.music {
    justify-content: flex-start;
  }
  .if__button-list.music::before {
    width: 100%;
    left: 0;
  }
}
@media screen and (min-width: 1240px) {
  .if__image {
    width: 620px;
    height: 500px;
  }
  .if__title {
    text-align: left;
    margin-bottom: 50px;
  }
}
.num42 {
  padding: 30px 0 0;
  text-align: center;
}
.num42__button-list.music {
  position: relative;
  justify-content: center;
  padding: 0;
  margin-top: 40px;
}
.num42__button-list.music .music__item:last-child {
  margin-right: 0;
}
.num42__button-list.music::before {
  position: absolute;
  content: '';
  display: block;
  left: calc(50% - 75px);
  top: -20px;
  width: 150px;
  height: 0;
  border-bottom: 2px solid rgba(227, 227, 227, 0.5);
}
@media screen and (min-width: 480px) {
  .num42 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 50px 0;
  }
  .num42__image {
    margin-right: auto;
  }
  .num42__title {
    text-align: right;
    margin: 0;
    margin-top: -50px;
  }
  .num42__button-list.music {
    align-self: center;
  }
}
@media screen and (min-width: 768px) {
  .num42 {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 100px;
  }
  .num42__content {
    width: 45%;
  }
  .num42__image {
    width: 350px;
    height: 350px;
    margin-right: 30px;
  }
  .num42__title {
    text-align: center;
    margin-top: 0;
  }
  .num42__button-list.music {
    justify-content: flex-start;
  }
  .num42__button-list.music::before {
    width: 100%;
    left: 0;
  }
}
@media screen and (min-width: 1240px) {
  .num42__image {
    width: 500px;
    height: 500px;
  }
  .num42__title {
    text-align: left;
    margin-bottom: 50px;
  }
}
.regatta {
  padding: 30px 0 0;
  text-align: center;
}
.regatta__text {
  position: relative;
}
.regatta__button-list.music {
  position: relative;
  justify-content: center;
  padding: 0;
  margin-top: 40px;
}
.regatta__button-list.music::before {
  position: absolute;
  content: '';
  display: block;
  left: calc(50% - 75px);
  top: -20px;
  width: 150px;
  height: 0;
  border-bottom: 2px solid rgba(227, 227, 227, 0.5);
}
.regatta__quote {
  font-size: 12px;
  font-style: italic;
  color: #E3E3E3;
}
@media screen and (min-width: 480px) {
  .regatta {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 50px 0;
  }
  .regatta__title {
    text-align: left;
    margin: 0;
    margin-top: -50px;
    margin-left: 30px;
  }
  .regatta__button-list.music {
    align-self: center;
  }
}
@media screen and (min-width: 768px) {
  .regatta {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 100px;
    align-items: flex-start;
  }
  .regatta__content {
    width: 45%;
  }
  .regatta__image {
    width: 350px;
    height: 350px;
    margin-right: 30px;
  }
  .regatta__title {
    text-align: left;
    margin-left: 0;
    margin-top: 0;
  }
  .regatta__button-list.music {
    justify-content: flex-start;
  }
  .regatta__button-list.music::before {
    width: 100%;
    left: 0;
  }
}
@media screen and (min-width: 1240px) {
  .regatta__image {
    width: 620px;
    height: 560px;
  }
  .regatta__title {
    margin-bottom: 50px;
  }
}
.media {
  padding: 30px 0 0;
  text-align: center;
}
.media__wrap {
  position: relative;
  background-color: #090909;
  padding: 30px 0 50px;
}
.media__video {
  /*padding-bottom: 56.25%; 
  padding-top: 0;
  height: 0;
  overflow: hidden;*/
}
.media__video iframe {
  max-width: 560px;
  max-height: 315px;
  width: 280px;
  height: 210px;
}
.media__title {
  margin-top: 0;
}
.media__list {
  position: relative;
  display: grid;
  grid-template-columns: 32% 32% 32%;
  gap: 10px;
  width: 280px;
  list-style: none;
  padding: 0;
  margin: 0 auto 30px;
}
.media__list::before {
  position: absolute;
  content: '';
  width: 0;
  height: 170px;
  top: 36px;
  right: -20px;
  border-left: 1px solid rgba(227, 227, 227, 0.5);
}
.media__list::after {
  position: absolute;
  content: '';
  width: 170px;
  height: 0;
  bottom: -20px;
  left: -20px;
  border-top: 1px solid rgba(227, 227, 227, 0.5);
}
.media__item img:hover {
  transition: 0.3s ease-in-out;
  transform: scale(1.3);
}
.media__item--1 {
  grid-row: 1 / span 2;
}
.media__item--2 {
  grid-column: 2 / span 2;
  grid-row: 0.5;
}
.media__item--3 {
  grid-column-start: 2;
  grid-row: 0.66666667;
}
.media__item--4 {
  grid-column-start: 3;
  grid-row: 2 / span 2;
}
.media__item--5 {
  grid-column: 1 / span 2;
  grid-row: 3 / span 2;
}
.media__item--6 {
  grid-column: 0.75;
  grid-row: 0.8;
}
.media__button {
  bottom: 80px;
  left: calc(50% - 120px);
  margin: 0;
}
@media screen and (min-width: 480px) {
  .media__list {
    width: 400px;
  }
}
@media screen and (min-width: 768px) {
  .media__list {
    width: 690px;
  }
  .media__list::before {
    height: 550px;
  }
  .media__list::after {
    width: 550px;
    left: 0;
  }
  .media__video {
    display: flex;
    justify-content: space-between;
    width: 690px;
    margin: 0 auto;
  }
}
@media screen and (min-width: 1000px) {
  .media__list {
    width: 880px;
  }
  .media__video {
    width: 880px;
  }
  .media__video iframe {
    width: 420px;
    height: 315px;
  }
}
@media screen and (min-width: 1240px) {
  .media__list {
    width: 1160px;
    gap: 24px;
  }
  .media__video {
    width: 1160px;
  }
  .media__video iframe {
    width: 560px;
    height: 420px;
  }
}
.nav {
  box-sizing: border-box;
  padding: 20px;
  padding-left: 0;
  max-width: 380px;
}
.nav__list {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-left: 20px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);
}
.nav__item {
  margin: 40px 0px;
}
.nav__link {
  display: block;
  font-family: 'Lemon-Milk', 'Arial', sans-serif;
  color: #ffffff;
  text-decoration: none;
  font-size: 28px;
  line-height: 40px;
  letter-spacing: 0.05em;
}
.nav__link:hover {
  color: #EF1C25;
}
@media screen and (min-width: 480px) {
  .nav {
    padding: 40px;
    padding-left: 0;
  }
  .nav__list {
    padding-left: 40px;
  }
  .nav__social-list.social {
    margin-left: 20px;
  }
}
@media screen and (min-width: 768px) {
  .nav {
    padding: 0;
    max-width: none;
  }
  .nav__list {
    display: none;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
    flex-grow: 1;
    border: none;
  }
  .nav__item {
    margin: 0;
  }
  .nav__link {
    margin: 0;
    font-family: 'Ubuntu', 'Arial', sans-serif;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
  }
  .nav__music.music {
    display: none;
  }
  .nav__social-list.social {
    padding: 0;
    margin-left: 0;
    margin-bottom: 20px;
  }
  .nav__social-list .social__item {
    margin: 0;
    margin-left: 20px;
  }
}
@media screen and (min-width: 1000px) {
  .nav__list {
    margin-right: 50px;
  }
  .nav__link {
    margin: 0;
    font-family: 'Ubuntu', 'Arial', sans-serif;
    font-size: 16px;
    line-height: 18px;
  }
}
.social {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 20px;
  margin: 0;
  list-style: none;
}
.social__item {
  margin-right: 40px;
}
.social__link:hover svg {
  transform: rotate(30deg);
}
/*
@media screen and (min-width: @mobile-xl) {
  .social {
    padding: 50px 40px 40px 40px;
  }
}*/
.music {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-left: 20px;
  margin: 0;
  list-style: none;
}
.music__item {
  margin-right: 20px;
}
@media screen and (min-width: 480px) {
  .music {
    padding-left: 40px;
  }
}
.footer {
  background-image: url('../img/bg-noise.png');
  padding: 30px 0;
  text-align: center;
}
.footer__wrap {
  border-top: 2px solid rgba(227, 227, 227, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer__social-list.social {
  width: 280px;
  align-items: center;
  justify-content: center;
}
.footer__social-list.social .social__item {
  margin-right: 20px;
}
.footer__social-list.social .social__item:last-child {
  margin-right: 0;
}
.footer__nav.nav {
  display: none;
}
.footer__copyright {
  font-style: normal;
  font-weight: normal;
  font-size: 8px;
  line-height: 9px;
  color: #E3E3E3;
  margin: 0;
}
.footer__copyright a {
  color: #63c8ea;
}
@media screen and (min-width: 480px) {
  .footer__wrap {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .footer__social-list.social {
    justify-content: flex-end;
  }
}
@media screen and (min-width: 768px) {
  .footer__copyright {
    font-size: 14px;
    line-height: 16px;
    text-align: left;
  }
  .footer__nav.nav {
    display: block;
    max-width: 1220px;
  }
  .footer__nav.nav .nav__list {
    display: flex;
    margin: 50px;
  }
}
