.media {
  padding: 30px 0 0;
  text-align: center;
}

.media__wrap {
  position: relative;
  background-color: @dark;
  padding: 30px 0 50px;
}

.media__video {
  /*padding-bottom: 56.25%; 
  padding-top: 0;
  height: 0;
  overflow: hidden;*/
  
  iframe {
    max-width: 560px;
    max-height: 315px;
    width: 280px;
    height: 210px;

  }
}

.media__title {
  margin-top: 0;
}

.media__list {
  position: relative;
  display: grid;
  grid-template-columns: 32% 32% 32%;
  gap: 10px;
  width: 280px;
  list-style: none;
  padding: 0;
  margin: 0 auto 30px;

  &::before {
    position: absolute;
    content: '';
    width: 0;
    height: 170px;
    top: 36px;
    right: -20px;
    border-left: 1px solid rgba(227, 227, 227, 0.5);
  }

  &::after {
    position: absolute;
    content: '';
    width: 170px;
    height: 0;
    bottom: -20px;
    left: -20px;
    border-top: 1px solid rgba(227, 227, 227, 0.5);
  }
}

.media__item img {
  //transform: scale(1);
  &:hover {
    
    transition: .3s ease-in-out;
    transform: scale(1.3);
  }
}

.media__item--1 {
  grid-row: 1 / span 2;
}

.media__item--2 {
  grid-column: 2 / span 2;
  grid-row: 1/2;
}

.media__item--3 {
  grid-column-start: 2;
  grid-row: 2/3;
}

.media__item--4 {
  grid-column-start: 3;
  grid-row: 2 / span 2;
}

.media__item--5 {
  grid-column: 1 / span 2;
  grid-row: 3 / span 2;
}

.media__item--6 {
  grid-column: 3/4;
  grid-row: 4/5;
}

.media__button {
  bottom: 80px;
  left: calc(50% - 120px);

  margin: 0;
}

@media screen and (min-width: @mobile-xl) {
  .media__list {
    width: 400px;
  }
}

@media screen and (min-width: @tablet) {
  .media__list {
    width: 690px;

    &::before {
      height: 550px;
    }

    &::after {
      width: 550px;
      left: 0;
    }
  }

  .media__video {
    display: flex;
    justify-content: space-between;
    width: 690px;
    margin: 0 auto;
  }
  
}


@media screen and (min-width: @tablet-xl) {
  .media__list {
    width: 880px;
  }

  .media__video {
    width: 880px;

    iframe {
      width: 420px;
      height: 315px;
    }
  }
}

@media screen and (min-width: @desktop) {
  .media__list {
    width: 1160px;
    gap: 24px;
  }

  .media__video {
    width: 1160px;

    iframe {
      width: 560px;
      height: 420px;
    }
  }

}